import { createContext, createRef, useCallback, useEffect, useState } from 'react'
import { BROWSER_ENUM, DESKTOP_MIN_HEIGHT, DESKTOP_MIN_WIDTH, OS_ENUM } from '../configs'
import { noop } from '../utils'

const defaultClientContextValue = {
  width: DESKTOP_MIN_WIDTH,
  height: DESKTOP_MIN_HEIGHT,
  isIOS: false,
  isMac: false,
  isDesktop: true,
  isMobile: false,
  isWindows: true,
  os: OS_ENUM.WINDOWS,
  browser: BROWSER_ENUM.UNKNOWN,
  isFullScreen: !!document.fullscreenElement,
  isIOSFullScreen: document.location.search.includes('noopen'),
  playerViewWrapperRef: createRef(),
  isFullScreenHandler: noop,
  shouldCheckVideoForReadyState: false,
}
export const ClientContext = createContext(defaultClientContextValue)

export const ClientContextProvider = ({ children }) => {
  const [contextValue, setContextValue] = useState(defaultClientContextValue)

  const isFullScreenHandler = useCallback((value) => {
    setContextValue((ctx) => ({ ...ctx, isFullScreen: value }))
  }, [])

  useEffect(() => {
    let currentContextValue = contextValue

    if (isFullScreenHandler !== currentContextValue.isFullScreenHandler) {
      currentContextValue = { ...currentContextValue, isFullScreenHandler }
    }

    if (contextValue !== currentContextValue) {
      setContextValue(currentContextValue)
    }
  }, [isFullScreenHandler])

  useEffect(() => {
    // set on what user access player
    const checkPlatform = () => {
      if (typeof window === `undefined` || typeof navigator === `undefined`) {
        setContextValue((ctx) => ({ ...ctx, browser: undefined }))
      }

      if (window.navigator.userAgent.includes(OS_ENUM.WINDOWS)) {
        setContextValue((ctx) => ({ ...ctx, isWindows: true }))
        if (window.navigator.userAgent.includes(BROWSER_ENUM.EDGE)) {
          setContextValue((ctx) => ({ ...ctx, browser: BROWSER_ENUM.EDGE }))
        } else if (window.navigator.userAgent.includes(BROWSER_ENUM.FIREFOX)) {
          setContextValue((ctx) => ({ ...ctx, browser: BROWSER_ENUM.FIREFOX }))
        } else if (window.navigator.userAgent.includes(BROWSER_ENUM.CHROME)) {
          setContextValue((ctx) => ({ ...ctx, browser: BROWSER_ENUM.CHROME }))
        }
      } else if (window.navigator.userAgent.includes(OS_ENUM.IPHONE)) {
        setContextValue((ctx) => ({ ...ctx, isIOS: true, shouldCheckVideoForReadyState: true }))
        if (window.navigator.userAgent.includes(OS_ENUM.CRI_OS)) {
          setContextValue((ctx) => ({ ...ctx, browser: BROWSER_ENUM.CHROME }))
        } else {
          setContextValue((ctx) => ({ ...ctx, browser: BROWSER_ENUM.SAFARI, shouldCheckVideoForReadyState: true }))
        }
      } else if (window.navigator.userAgent.includes(OS_ENUM.ANDROID)) {
        setContextValue((ctx) => ({ ...ctx, os: OS_ENUM.ANDROID }))
      } else if (
        window.navigator.userAgent.includes(BROWSER_ENUM.SAFARI) &&
        window.navigator.userAgent.includes(OS_ENUM.MAC)
      ) {
        setContextValue((ctx) => ({ ...ctx, browser: BROWSER_ENUM.SAFARI, shouldCheckVideoForReadyState: true }))
      } else {
        setContextValue((ctx) => ({ ...ctx, browser: BROWSER_ENUM.UNKNOWN, shouldCheckVideoForReadyState: true }))
        /**
         * TODO: There is no checks for Macs and other apple devices, same with android
         * so for now they are undefined as have no way to check it
         */
      }
    }

    checkPlatform()
  }, [])

  return <ClientContext.Provider value={contextValue}>{children}</ClientContext.Provider>
}
