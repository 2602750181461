import { useRef, useState } from 'react'
import { Button } from 'antd'
import { motion } from 'framer-motion'
import { QuizAnswers } from './QuizAnswers'
import { TIMESHIFT_SLIDE_END_PLAY } from '../../../utils'
import { useNotificationContext } from '../../../hooks/useNotificationContext'
import { ARIA_LIVE_MODES, MESSAGE_TYPE } from '../../../contexts/NotificationContext'
import { TRANSITION_TIME, transitionTemplate } from '../../../constants/constants'

import { usePlayerContext, useVideoDataContext, useScheduledPlayerContext, useAnalyticContext } from '../../../hooks'

import './quiz-styles.less'

export const Quiz = (props) => {
  const { interactiveItem: quizData, onInteractivityFinished } = props
  const [chosenAnswers, setChosenAnswers] = useState({})

  const { videoSchedule } = useVideoDataContext()
  const { playHandler } = usePlayerContext()
  const { rewindOnSlideHandler } = useScheduledPlayerContext()
  const { showNotification } = useNotificationContext()
  const { playerBounding, videoReadyHandler, isVideoReady } = usePlayerContext()
  const { analyticCollector } = useAnalyticContext()

  const timerId = useRef(null)

  const isDisabledSubmit = !isVideoReady || quizData.answerState.length

  const onSubmitHandler = (event) => {
    event.preventDefault()

    const chosenAnswersArray = Object.entries(chosenAnswers).reduce((array, [answerId, isChecked]) => {
      if (isChecked) array.push(answerId)
      return array
    }, [])

    if (!chosenAnswersArray.length) {
      showNotification(
        { content: 'Please select an answer!' },
        'Please select an answer!',
        MESSAGE_TYPE.WARNING,
        ARIA_LIVE_MODES.ASSERTIVE,
      )
      return
    }
    const isCorrect = quizData.checkAnswers(chosenAnswersArray)

    analyticCollector.setAnswerClick(chosenAnswersArray, isCorrect, quizData.id)

    const nextData = isCorrect ? quizData.getOnCorrect() : quizData.getOnWrong()
    const { feedbackMessage } = nextData ?? {}
    if (feedbackMessage) {
      showNotification(
        { content: feedbackMessage },
        feedbackMessage,
        isCorrect ? MESSAGE_TYPE.SUCCESS : MESSAGE_TYPE.ERROR,
        ARIA_LIVE_MODES.ASSERTIVE,
      )
    }
    videoReadyHandler(false)
    timerId.current = setTimeout(() => {
      const targetSlide = videoSchedule?.slidesMetaHashMap?.[nextData?.slide] ?? null
      if (!targetSlide) {
        onInteractivityFinished(() => playHandler(TIMESHIFT_SLIDE_END_PLAY))
      } else {
        onInteractivityFinished(() => rewindOnSlideHandler(nextData.slide))
      }
      videoReadyHandler(true)
    }, 2000)
  }
  const {
    viewObjects: { quizBackground, quizQuestion, quizSendButton },
  } = quizData
  const quizQuestionText = quizQuestion.text

  return (
    <motion.form
      className="quiz-root"
      {...transitionTemplate}
      style={quizData.getElementStyles(playerBounding, quizBackground)}
      onSubmit={onSubmitHandler}
    >
      <h3 className="sr-only">Interactive Quiz</h3>
      <motion.h4
        {...transitionTemplate}
        transition={{ duration: TRANSITION_TIME + 0.2, delay: 0.4 }}
        className="quiz-question"
        style={quizData.getElementStyles(playerBounding, quizQuestion)}
      >
        <span className="sr-only">{quizQuestionText}</span>
      </motion.h4>
      <QuizAnswers
        quizQuestionText={quizQuestionText}
        quizData={quizData}
        chosenAnswers={chosenAnswers}
        setChosenAnswers={setChosenAnswers}
      />
      <motion.div {...transitionTemplate} transition={{ duration: TRANSITION_TIME + 0.2, delay: 0.4 }}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={isDisabledSubmit}
          className="quiz-submit-button"
          style={{
            display: 'flex',
            color: '#fff',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: quizSendButton.height * 0.2 * playerBounding.ratioY,
            fontSize: quizSendButton.height * 0.4 * playerBounding.ratioY,
            ...quizData.getElementStyles(playerBounding, quizSendButton),
            transform: 'none',
          }}
        >
          <span className="sr-only">SUBMIT</span>
          <span style={{ position: 'relative' }}>Submit</span>
        </Button>
      </motion.div>
    </motion.form>
  )
}
