import { MIN_GAP_BETWEEN_EVENTS, NEED_EXTENDED_RANGE, RANGE_BOUND } from '../constants'
import { createGroupedHashMap, createHashMap, sortASC } from '../helpers'
import { hasScheduleTimeSlideStartEvent } from './helper-fns'
import { InteractiveItemsFabric } from './interactive-helpers'
import { setMarks } from './marks'
import { EVENT_TYPE } from './types'

/*
  VideoScheduler is a class
  It creates an object that has all additional info about video but this is not the main goal.

  It implements an interface that helps register actions for some timestamp happened
  and run these actions by timeKey - time in seconds.

  It has #timeKeysSet for saving unique time values but without right order
  It has timeKeysQueue for iterating over to find out what is next by a schedule should be
  It has #schedule that is a Map instance with an array of callbacks are set for a some timeKey

  There is a way to register some callback - just call registerAction(timeKey, ...cbs)
  Also it is posible to run actions -> just call runActions(timeKey)
  After all actions by a key were done the key will be deleted from timeKeysQueue,
  but left in #timeKeysSet because there should be a way to restart a video again.
  For this purpose there is a restartSchedule method

*/

export class VideoScheduler {
  #timeKeysSet = new Set()
  #schedule = new Map()
  #videoElement = null

  #init() {
    let timeAcc = 0
    let hasVideoInteractivity = false
    let hasVideoQuizes = false
    this.isNavigationAllowed = this.videoData.locked !== undefined ? !this.videoData.locked : true
    this.videoData.slides.forEach((slide, index, slides) => {
      // collect meta info about slide
      // add start/end time into schedule
      // create slides hash map
      const isFirstSlide = index === 0
      const isLastSlide = index === slides.length - 1
      const isColorOrNoTransition = ['fadewhite', 'fadeblack', null, undefined].includes(slide.transition)
      let transitionShift = isColorOrNoTransition ? -0.02 : 0.4
      const slideId = slide.id
      const slideDuration = slide.duration ? slide.duration - transitionShift : 0
      const startTime = timeAcc
      const nextSlideStartTime = timeAcc + slideDuration
      const endTime = isLastSlide ? this.duration : nextSlideStartTime - MIN_GAP_BETWEEN_EVENTS * 2
      this.slidesHashMap[slideId] = slide

      // process objects into hash map
      // create adapters for interactive items -> group objects by interactive elemet type
      const objects = slide.objects ?? []
      const slideObjectsHashMap = {}
      objects.forEach(createHashMap({ hashMap: slideObjectsHashMap }))
      this.objectsHashMap = { ...this.objectsHashMap, ...slideObjectsHashMap }

      const defaultObjects = slide.defaultObjects ?? []
      const defaultObjectsGroupedHashMap = {}
      defaultObjects.forEach(
        createGroupedHashMap({ fieldName: 'answerGroupId', hashMap: defaultObjectsGroupedHashMap }),
      )
      const interactiveElementsOfSlide = new InteractiveItemsFabric(
        { slide, startTime, endTime, slideId },
        objects,
        defaultObjectsGroupedHashMap,
        slideObjectsHashMap,
      )

      // save slide meta information about slide separately from the slide
      if (!hasVideoInteractivity) hasVideoInteractivity = interactiveElementsOfSlide.hasInteractivity
      if (!hasVideoQuizes) hasVideoQuizes = interactiveElementsOfSlide.hasQuizes
      this.hasVideoInteractivity = hasVideoInteractivity
      this.hasVideoQuizes = hasVideoQuizes

      const slideMeta = {
        startTime,
        endTime,
        objectsHashMap: slideObjectsHashMap,
        defaultObjectsGroupedHashMap,
        hasInteractivity: interactiveElementsOfSlide.hasInteractivity,
        interactiveElementsOfSlide,
        nextNavigationSlide: slide.nextSlide,
        nextSlideId: slides[index + 1]?.id,
        prevSlideId: slides[index - 1]?.id,
        interactiveItemsSchedule: interactiveElementsOfSlide.interactiveItemsSchedule,
      }
      this.slidesMetaHashMap[slideId] = slideMeta

      setMarks({
        accumulatorObject: this.timeToMarkMap,
        accumalatorList: this.marks,
        slide,
        slideMeta,
        videoDuration: this.duration,
      })

      // save meta info about events -> need to set actions on this times in React components
      const eventStartData = {
        slideId,
        eventType: EVENT_TYPE.SLIDE_START,
        time: startTime,
        objectId: null,
        isFirstSlide,
        isLastSlide,
      }
      const eventEndData = {
        slideId,
        eventType: EVENT_TYPE.SLIDE_END,
        time: endTime,
        objectId: null,
        isFirstSlide,
        isLastSlide,
        nextNavigationSlide: slide.nextSlide,
      }
      this.slidesSchedule.push({ slideId, startTime, endTime })
      // schedule slide start events
      this.#enqueueTime(startTime)
      this.#registerEvent(startTime, eventStartData)
      if (slide.nextSlide) {
        this.#enqueueTime(endTime)
        this.#registerEvent(endTime, eventEndData)
      }

      timeAcc = nextSlideStartTime

      // schedule objects' events
      const { buttons, links, quizes } = interactiveElementsOfSlide
      ;[...buttons, ...links, ...quizes].forEach((interactiveElement) => {
        const [times, timeMap] = interactiveElement.scheduleObjects
        times.forEach((time) => {
          this.#enqueueTime(time)
          this.#registerEvents(time, timeMap[time])
        })

        // schedule slide end events
        this.#enqueueTime(endTime)
        this.#registerEvent(endTime, eventEndData)
      })
    })
    console.log(+timeAcc.toFixed(3), this.#videoElement.duration)
  }

  #registerEvents(time, eventsMeta) {
    if (typeof time !== 'number') return
    if (!eventsMeta) return
    if (!Array.isArray(eventsMeta)) return
    eventsMeta.forEach((eventMeta) => this.#registerEvent(time, eventMeta))
  }
  #registerEvent(time, eventMeta) {
    if (typeof time !== 'number') return
    if (!eventMeta) return
    const alreadyScheduledEvents = this.eventsMetaGroupedHashMap[time]
    if (!Array.isArray(alreadyScheduledEvents)) {
      this.eventsMetaGroupedHashMap[time] = [eventMeta]
    } else {
      alreadyScheduledEvents.push(eventMeta)
    }
  }

  #enqueueTime(timeKey) {
    this.#timeKeysSet.add(timeKey)
    this.timeKeysQueue = [...this.#timeKeysSet].sort(sortASC)
  }

  constructor({ video, slidesHashMap = {}, objectsHashMap = {}, videoId, videoElement }) {
    this.videoId = videoId
    this.videoData = video
    this.#videoElement = videoElement
    this.timeKeysQueue = []

    this.objectsHashMap = objectsHashMap
    this.slidesHashMap = slidesHashMap
    this.slidesSchedule = []

    this.marks = []
    this.timeToMarkMap = {}

    this.slidesMetaHashMap = {}
    this.eventsMetaGroupedHashMap = {}

    this.hasVideoInteractivity = false
    this.hasVideoQuizes = false
    this.hasSubtitles = video.subtitlesEnabled === 'vtt'
    // Safari in the end of video show empty (white) frame. So set less when real video duration
    this.duration = this.#videoElement.duration - (NEED_EXTENDED_RANGE ? RANGE_BOUND : 0)
    this.isNavigationAllowed = true

    this.#init()
  }

  registerAction(timeKey, ...callbacks) {
    if (typeof timeKey !== 'number') return
    const onlyFunctions = callbacks.filter((cb) => typeof cb === 'function')

    this.#enqueueTime(timeKey)
    const currentCallbacks = this.#schedule.get(timeKey) ?? []
    this.#schedule.set(timeKey, [...currentCallbacks, ...onlyFunctions])
  }
  runActions(timeKey) {
    const actions = this.#schedule.get(timeKey) ?? []
    if (actions.length === 0) return
    actions.forEach((action) => action())
  }
  getSlideIdByTime(time) {
    // TODO -> use this.slidesSchedule array instead of too many itterations over all schedule
    let slideId = this.videoData.slides[0].id
    let eventCandidate = this.eventsMetaGroupedHashMap[0].find(hasScheduleTimeSlideStartEvent)
    for (let timeInSchedule of this.timeKeysQueue) {
      if (timeInSchedule <= time) {
        eventCandidate =
          this.eventsMetaGroupedHashMap[timeInSchedule].find(hasScheduleTimeSlideStartEvent) ?? eventCandidate
      } else {
        break
      }
    }
    slideId = eventCandidate?.slideId ?? slideId
    return slideId
  }
}
