import { createContext, useEffect, useState } from 'react'

/*
  video : Video from MongoDB schema
  slidesHashMap: { slideId: Slide }
  scheduleItemsMap: { timeMs: Object | Slide }
*/

const defaultVideoDataContextValue = {
  video: null,
  videoSchedule: null,
  slidesHashMap: null,
  objectsHashMap: null,
  markersHashMap: null,
  chaptersHashMap: null,
  scheduleItemsMap: null,
}
export const VideoDataContext = createContext(defaultVideoDataContextValue)

export const VideoDataContextProvider = (props) => {
  const { children, videoSchedule, video } = props
  const [contextValue, setContextValue] = useState(defaultVideoDataContextValue)
  useEffect(() => {
    if (!videoSchedule) return
    setContextValue((ctxVal) => ({ ...ctxVal, videoSchedule }))
    console.log({ videoSchedule })
  }, [videoSchedule])
  useEffect(() => {
    if (!video) return
    setContextValue((ctxVal) => ({ ...ctxVal, video }))
  }, [video])
  return <VideoDataContext.Provider value={contextValue}>{children}</VideoDataContext.Provider>
}
