import { sortASC } from '../helpers'
import {
  ANSWERS_CONTAINER_SUFIX_QUIZ_ID,
  ANSWERS_GROUP_SUFIX_QUIZ_ID,
  ANSWER_BACKGROUND_SUFIX_QUIZ_ID,
  ANSWER_CHECKBOX_SUFIX_QUIZ_ID,
  ANSWER_RADIO_SUFIX_QUIZ_ID,
  ANSWER_SUB_OBJECT_SUFIX_QUIZ_ID,
  ANSWER_TEXT_SUFIX_QUIZ_ID,
  BACKGROUND_SUFIX_QUIZ_ID,
  BUTTON_SEND_SUFIX_QUIZ_ID,
  EVENT_TYPE,
  INTERACTIVE_ACTION_TYPE,
  OBJECT_TYPE,
  QUESTION_SUFIX_QUIZ_ID,
  QUIZ_CONTROL_TYPES,
} from './types'
import { MIN_GAP_BETWEEN_EVENTS } from '../constants'

export const isQuizSubObject = ({ id = '' }) => {
  return [
    QUESTION_SUFIX_QUIZ_ID,
    BACKGROUND_SUFIX_QUIZ_ID,
    BUTTON_SEND_SUFIX_QUIZ_ID,
    ANSWERS_CONTAINER_SUFIX_QUIZ_ID,
    ANSWERS_GROUP_SUFIX_QUIZ_ID,
    ANSWER_CHECKBOX_SUFIX_QUIZ_ID,
    ANSWER_BACKGROUND_SUFIX_QUIZ_ID,
    ANSWER_TEXT_SUFIX_QUIZ_ID,
  ].some((idType) => id.includes(idType))
}

export const isQuizControl = (id = '') =>
  id.includes(QUIZ_CONTROL_TYPES.CHECKBOX) || id.includes(QUIZ_CONTROL_TYPES.RADIO)

export const getObjectType = (object = {}) => {
  const { id } = object
  if (!id) return OBJECT_TYPE.UNKNOWN
  if (typeof id !== 'string') return OBJECT_TYPE.UNKNOWN
  switch (true) {
    case id.includes(QUESTION_SUFIX_QUIZ_ID):
      return OBJECT_TYPE.QUIZ_QUESTION
    case id.includes(BACKGROUND_SUFIX_QUIZ_ID):
      return OBJECT_TYPE.QUIZ_BACKGROUND
    case id.includes(BUTTON_SEND_SUFIX_QUIZ_ID):
      return OBJECT_TYPE.QUIZ_BUTTON_SEND

    case id.includes(ANSWERS_CONTAINER_SUFIX_QUIZ_ID):
      return OBJECT_TYPE.ANSWERS_CONTAINER

    case id.includes(ANSWER_CHECKBOX_SUFIX_QUIZ_ID):
      return OBJECT_TYPE.ANSWER_CONTROL_CHECKBOX
    case id.includes(ANSWER_RADIO_SUFIX_QUIZ_ID):
      return OBJECT_TYPE.ANSWER_CONTROL_RADIO

    case id.includes(ANSWER_BACKGROUND_SUFIX_QUIZ_ID):
      return OBJECT_TYPE.ANSWER_BACKGROUND
    case id.includes(ANSWER_TEXT_SUFIX_QUIZ_ID):
      return OBJECT_TYPE.ANSWER_TEXT
    default:
      return OBJECT_TYPE.OTHER
  }
}

export const isClickable = ({ id = '', action }) => id.includes(ANSWER_SUB_OBJECT_SUFIX_QUIZ_ID) || !!action

export const isButton = ({ action = {} }) => action.type === INTERACTIVE_ACTION_TYPE.SLIDE_ACTION
export const isLink = ({ action = {} }) => action.type === INTERACTIVE_ACTION_TYPE.LINK_ACTION

export const parseGroupIdFromObjectId = (objectId) => objectId.match(/o_([0-9]+-[0-9]+)/)?.[1]
export const parseAnswerIdFromObjectId = (objectId) => objectId.match(/answers_group-answer-([0-9]+)-/)?.[1]

export const preloadMediaElement = ({ src, type }) => {
  if (!src) return
  const element = type === 'video' ? document.createElement('video') : new Image()
  element.crossOrigin = 'anonymous'
  element.src = src
}

// const element = document.createElement('link')
// element.rel = 'preload'
// element.href = 'src'
// element.as = 'image'

// common logic for any interactive element -> they all are schedulable
export class InteractiveSchedulableItem {
  _schedule = new Set()
  _scheduleObjectGroupedMap = {}
  _setScheduleObjects(time, objectScheduleMeta) {
    if (typeof time !== 'number') return
    if (Number.isNaN(time)) return
    this._schedule.add(time)
    const readScheduleData = this._scheduleObjectGroupedMap[time]
    if (Array.isArray(readScheduleData)) {
      readScheduleData.push(objectScheduleMeta)
    } else {
      this._scheduleObjectGroupedMap[time] = [objectScheduleMeta]
    }
  }
  _setStartEndTime(startTime, endTime) {
    const startTimeNumber = +startTime
    const endTimeNumber = +endTime
    if (Number.isNaN(startTimeNumber) || Number.isNaN(endTimeNumber)) return
    this.startTime = startTimeNumber
    this.endTime = endTimeNumber
  }
  constructor(interactiveItemType) {
    this.itemType = interactiveItemType
  }
  // getter returns tuple of schedule (array of times sorted by ASC)
  // and scheduleObjectGroupedMap (meta info about which object at what time should appear, key -> time, value -> array of objects)
  get scheduleObjects() {
    return [[...this._schedule].sort(sortASC), { ...this._scheduleObjectGroupedMap }]
  }
  getInstanceStartTime() {
    return [...this._schedule].sort(sortASC)[0]
  }
  getInstanceEndTime() {
    return [...this._schedule].sort(sortASC)?.[1]
  }
  getElementStyles(bounding, obj, topRecalcValue = 0, leftRecalcValue = 0) {
    const { ratioY, x, y } = bounding
    const { width, height, top, left, src, scaleX, scaleY, rotate } = obj
    let transform = ''
    transform += scaleX ? `scaleX(${scaleX}) ` : ''
    transform += scaleY ? `scaleY(${scaleY}) ` : ''
    transform += rotate ? `rotate(${rotate}deg) ` : ''
    return {
      top: y + (top - topRecalcValue) * ratioY,
      left: x + (left - leftRecalcValue) * ratioY,
      width: width * ratioY,
      height: height * ratioY,
      transform,
      transformOrigin: transform.length ? 'top left' : '',
      // provide real dimensions in the background to make the image smoother
      background: `url(${src}) 0% 0% / ${width * ratioY}px ${height * ratioY}px no-repeat`,
    }
  }
}

export const hasScheduleTimeSomeEvent =
  (eventToSeek) =>
  ({ eventType }) =>
    eventType === eventToSeek
export const hasScheduleTimeSlideStartEvent = hasScheduleTimeSomeEvent(EVENT_TYPE.SLIDE_START)
export const hasScheduleTimeSlideEndEvent = hasScheduleTimeSomeEvent(EVENT_TYPE.SLIDE_END)

export class InteractiveBuilder {
  static calcStartObjectTime(object, slideStartTime) {
    const { animation } = object
    if (!animation) return slideStartTime + MIN_GAP_BETWEEN_EVENTS

    const { startTime } = animation
    const isStartTimeIsNumber = typeof startTime === 'number'
    if (isStartTimeIsNumber && startTime) return slideStartTime + startTime
    return slideStartTime + MIN_GAP_BETWEEN_EVENTS
  }
  static calcEndObjectTime(object, slideStartTime, slideEndTime) {
    const { animation } = object
    if (!animation) return slideEndTime

    const { endTime } = animation
    const isEndTimeIsNumber = typeof endTime === 'number'
    if (isEndTimeIsNumber && endTime > 0) return slideStartTime + endTime
    return slideEndTime // - MIN_GAP_BETWEEN_EVENTS
  }
}

export const getCurrentSubtitles = (videoElement, currentTime) => {
  if (!videoElement.textTracks[0]) return
  const { cues } = videoElement.textTracks[0]
  for (const [, cue] of Object.entries(cues)) {
    const { startTime, endTime, text } = cue
    if (startTime <= currentTime && currentTime <= endTime) {
      return text
    }
  }
  return ''
}
