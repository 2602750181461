import { InteractiveBuilder, InteractiveSchedulableItem, preloadMediaElement } from './helper-fns'
import { EVENT_TYPE, INTERACTIVE_ITEM_TYPE } from './types'

export class ButtonBuilder extends InteractiveBuilder {
  static buildButtons(objects, slideData, objectsHashMap) {
    if (!objects) return null
    if (!slideData) return null
    if (!Array.isArray(objects)) return null
    if (objects.length === 0) return null

    const { startTime, endTime, slideId } = slideData

    const buttonsInstances = objects.map((object) => new InteractiveButton(object, objectsHashMap))
    objects.forEach((object, idx) => {
      const interactiveButtonInstance = buttonsInstances[idx]
      const startObjectTime = ButtonBuilder.calcStartObjectTime(object, startTime)
      const endObjectTime = ButtonBuilder.calcEndObjectTime(object, startTime, endTime)
      const objectScheduleMeta = {
        slideId,
        objectId: object.id,
        object,
        interactiveItemType: INTERACTIVE_ITEM_TYPE.BUTTON,
        interactiveItem: interactiveButtonInstance,
        startTime: startObjectTime,
        endTime: endObjectTime,
      }
      interactiveButtonInstance._setScheduleObjects(startObjectTime, {
        ...objectScheduleMeta,
        eventType: EVENT_TYPE.OBJECT_START,
      })
      interactiveButtonInstance._setScheduleObjects(endObjectTime, {
        ...objectScheduleMeta,
        eventType: EVENT_TYPE.OBJECT_END,
      })
      preloadMediaElement(object)
    })
    buttonsInstances.forEach((buttonInstance) => {
      const startTime = buttonInstance.getInstanceStartTime()
      const endTime = buttonInstance.getInstanceEndTime()
      buttonInstance._setStartEndTime(startTime, endTime ?? slideData.endTime)
    })
    return buttonsInstances
  }
}

export class InteractiveButton extends InteractiveSchedulableItem {
  constructor(object, objectsHashMap) {
    super(INTERACTIVE_ITEM_TYPE.BUTTON)
    this.objectsHashMap = objectsHashMap
    this.object = object
    this.id = object.id
  }
  getActionStrategy() {
    return this.object.action
  }
}
